/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-21 15:45:29
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-10-16 16:29:30
 * @FilePath: /settlement-frontend/src/core/directive/function/settlementEntity.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { TaggingItem } from "../interface/common";
import { BadgeStyle, CommonLanguageType } from "../type/common";
import {
  SettlementEntityInsolvent,
  SettlementEntityStatus,
} from "../type/settlementEntity";

export const getSettlementEntityStatus = (
  status: number
): BadgeStyle | undefined => {
  const result = new Map([
    [SettlementEntityStatus.Activated, BadgeStyle.LightSuccess],
    [SettlementEntityStatus.Pending, BadgeStyle.LightPrimary],
    [SettlementEntityStatus.Deactivated, BadgeStyle.Light],
  ]);
  return result.get(status);
};

export const getSettlementEntityInsolventOptions = (
  language: CommonLanguageType
): TaggingItem[] => {
  return [
    {
      label: language("common.no"),
      value: String(SettlementEntityInsolvent.No),
    },
    {
      label: language("common.yes"),
      value: String(SettlementEntityInsolvent.Yes),
    },
  ];
};
