
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import _ from "lodash";
import { ApiBase, ApiDebtor, ApiSettlementEntity } from "@/core/api";
import { DebtorOption } from "@/core/directive/interface/debtor";
import { SettlementEntityCompanyTableFilter } from "@/core/directive/interface/settlementEntity";
import { TaggingItem } from "@/core/directive/interface/common";

export default defineComponent({
  name: "settlement-entity-company-dropdown",
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const formData = ref<SettlementEntityCompanyTableFilter>({
      status: "",
      vat_number: "",
      tax_number: "",
      name: "",
      city: "",
      country_code: "",
    });

    const options = ref({
      entityLoading: false,
      entityOptions: [] as DebtorOption[],
      country: [] as TaggingItem[],
      cityLoading: false,
      cityLoaded: false,
      cityOptions: [] as TaggingItem[],
      statusOptions: [] as TaggingItem[],
      currency: [
        {
          label: "EUR",
          value: "EUR",
        },
        {
          label: "CNY",
          value: "CNY",
        },
      ],
    });

    const getTaggingData = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: ["settlement_entity_status"],
      });
      if (data.code == 0) {
        options.value.statusOptions = data.data.settlement_entity_status.items;
      }
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.country = data.data;
      }
    };

    const querySearchAsync = async (
      queryString: string,
      cb: (arg: any) => void
    ) => {
      if (options.value.cityLoading || queryString.trim() === "") return cb([]);
      if (options.value.cityLoaded) {
        let filterArr: TaggingItem[] = options.value.cityOptions.filter(
          (item) =>
            (item.value as string)
              .toUpperCase()
              .indexOf(queryString.toUpperCase()) > -1
        );
        cb(filterArr);
        return false;
      }
      options.value.cityLoading = true;
      const { data } = await ApiSettlementEntity.cityOption({
        country_code: formData.value.country_code,
        value: queryString,
      });
      options.value.cityLoading = false;
      if (data.code == 0) {
        let arr: TaggingItem[] = [];
        data.data.map((item) => {
          arr.push({
            value: item.name,
            label: item.label,
          });
        });
        options.value.cityOptions = arr;
        cb(arr);
      }
    };

    const getEntityData = async (value) => {
      options.value.entityLoading = true;
      const { data } = await ApiDebtor.searchDebtor({
        value: value,
      });
      options.value.entityLoading = false;
      if (data.code == 0) {
        options.value.entityOptions = data.data;
      }
    };

    const debounceEntitySearch = _.debounce(getEntityData, 1000);

    const searchEntityItems = (query: string) => {
      debounceEntitySearch(query);
    };

    const submit = () => {
      emit("submit-filter", formData.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      getTaggingData();
      getCountryData();
    });

    return {
      t,
      formData,
      formRef,
      options,
      querySearchAsync,
      searchEntityItems,
      submit,
      handleReset,
    };
  },
});
