
import { defineComponent, ref } from "vue";
import SettlementEntityCompany from "./Company.vue";
import SettlementEntityIndividual from "./Individual.vue";

export default defineComponent({
  name: "settlement-entity",
  components: {
    SettlementEntityCompany,
    SettlementEntityIndividual,
  },
  setup() {
    const navCurrent = ref(0);

    const navChange = (val: number) => {
      navCurrent.value = val;
    };

    return {
      navCurrent,
      navChange,
    };
  },
});
