import { toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "d-flex flex-column gap-5" }
const _hoisted_2 = { class: "d-flex flex-column flex-row-fluid gap-7 gap-lg-10" }
const _hoisted_3 = {
  class: "nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder",
  role: "tablist"
}
const _hoisted_4 = { class: "nav-item" }
const _hoisted_5 = { class: "nav-item" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_SettlementEntityCompany = _resolveComponent("SettlementEntityCompany")!
  const _component_SettlementEntityIndividual = _resolveComponent("SettlementEntityIndividual")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("ul", _hoisted_3, [
        _createElementVNode("li", _hoisted_4, [
          _createElementVNode("span", {
            class: _normalizeClass(["nav-link text-active-primary cursor-pointer me-6", {
              active: _ctx.navCurrent === 0,
            }]),
            onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.navChange(0)))
          }, _toDisplayString(_ctx.$t("settlementEntity.company")), 3)
        ]),
        _createElementVNode("li", _hoisted_5, [
          _createElementVNode("span", {
            class: _normalizeClass(["nav-link text-active-primary cursor-pointer me-6", {
              active: _ctx.navCurrent === 1,
            }]),
            onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.navChange(1)))
          }, _toDisplayString(_ctx.$t("settlementEntity.individual")), 3)
        ])
      ])
    ]),
    (_ctx.navCurrent === 0)
      ? (_openBlock(), _createBlock(_component_SettlementEntityCompany, { key: 0 }))
      : (_ctx.navCurrent === 1)
        ? (_openBlock(), _createBlock(_component_SettlementEntityIndividual, { key: 1 }))
        : _createCommentVNode("", true)
  ]))
}