/*
 * @Author: xiang.gao xiang.gao@travel-easy.com
 * @Date: 2023-07-21 15:44:23
 * @LastEditors: xiang.gao xiang.gao@travel-easy.com
 * @LastEditTime: 2023-08-01 13:57:02
 * @FilePath: /settlement-frontend/src/core/directive/type/settlementEntity.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export enum SettlementEntityStatus {
  Activated = 10,
  Pending = 15,
  Deactivated = 25,
}

export enum SettlementEntityInsolvent {
  No = 0,
  Yes,
}
