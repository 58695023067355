import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, vModelText as _vModelText, withDirectives as _withDirectives, vShow as _vShow, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withModifiers as _withModifiers, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "card" }
const _hoisted_2 = { class: "card-header border-0 pt-6" }
const _hoisted_3 = { class: "card-title" }
const _hoisted_4 = { class: "d-flex align-items-center position-relative my-1" }
const _hoisted_5 = { class: "svg-icon svg-icon-1 position-absolute ms-4" }
const _hoisted_6 = ["placeholder"]
const _hoisted_7 = { class: "card-toolbar" }
const _hoisted_8 = {
  class: "d-flex gap-3 justify-content-end",
  "data-kt-customer-table-toolbar": "base"
}
const _hoisted_9 = { class: "position-relative" }
const _hoisted_10 = {
  type: "button",
  class: "btn btn-primary",
  "data-kt-menu-trigger": "click",
  "data-kt-menu-placement": "bottom-end"
}
const _hoisted_11 = { class: "svg-icon svg-icon-2" }
const _hoisted_12 = { class: "card-body pt-0" }
const _hoisted_13 = { key: 0 }
const _hoisted_14 = { key: 1 }
const _hoisted_15 = { class: "d-flex justify-content-end gap-2" }
const _hoisted_16 = { class: "svg-icon svg-icon-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_inline_svg_icon = _resolveComponent("inline-svg-icon")!
  const _component_PermissionCommon = _resolveComponent("PermissionCommon")!
  const _component_IndividualFilterDropdwon = _resolveComponent("IndividualFilterDropdwon")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_MBDatatable = _resolveComponent("MBDatatable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("span", _hoisted_5, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen021.svg" })
          ]),
          _withDirectives(_createElementVNode("input", {
            type: "text",
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.search) = $event)),
            onInput: _cache[1] || (_cache[1] = ($event: any) => (_ctx.searchItems())),
            class: "form-control form-control-solid w-250px ps-14",
            placeholder: _ctx.t('common.search')
          }, null, 40, _hoisted_6), [
            [_vModelText, _ctx.search]
          ]),
          _withDirectives(_createElementVNode("span", {
            class: "svg-icon svg-icon-1 position-absolute top-50 end-0 mr-2 translate-middle",
            style: {"cursor":"pointer"},
            onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.removeSearch && _ctx.removeSearch(...args)))
          }, [
            _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/arrows/arr061.svg" })
          ], 512), [
            [_vShow, _ctx.search != '']
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_7, [
        _createElementVNode("div", _hoisted_8, [
          _createVNode(_component_PermissionCommon, { role: { auth: ['add'] } }),
          _createElementVNode("div", _hoisted_9, [
            _createElementVNode("button", _hoisted_10, [
              _createElementVNode("span", _hoisted_11, [
                _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen031.svg" })
              ]),
              _createTextVNode(_toDisplayString(_ctx.t("common.filter")), 1)
            ]),
            _createVNode(_component_IndividualFilterDropdwon, {
              ref: "filterRef",
              onSubmitFilter: _ctx.handleFilter,
              onResetFilter: _ctx.handleFilterReset
            }, null, 8, ["onSubmitFilter", "onResetFilter"])
          ])
        ])
      ])
    ]),
    _createElementVNode("div", _hoisted_12, [
      _createVNode(_component_MBDatatable, {
        loading: _ctx.loading,
        "table-data": _ctx.tableData,
        "table-header": _ctx.tableHeader,
        order: "desc",
        "enable-items-per-page-dropdown": true,
        "current-page": _ctx.currentPage,
        total: _ctx.total,
        "rows-per-page": _ctx.pageSize,
        onSelectChange: _ctx.onRowsSelectChange,
        onCurrentChange: _ctx.onCurrentPageChange,
        onItemsPerPageChange: _ctx.onRowsPerPageChange,
        onSort: _ctx.onColumnSort
      }, {
        "cell-name": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.name ? item.name : "--"), 1)
        ]),
        "cell-mobile_number": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.mobile_number
              ? item.mobile_area_code + " " + item.mobile_number
              : "--"), 1)
        ]),
        "cell-passport_number": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.passport_number ? item.passport_number : "--"), 1)
        ]),
        "cell-city": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.city ? item.city : "--"), 1)
        ]),
        "cell-country": _withCtx(({ row: item }) => [
          _createTextVNode(_toDisplayString(item.__show.country_iso_2 ? item.__show.country_iso_2 : "--"), 1)
        ]),
        "cell-status": _withCtx(({ row: item }) => [
          _createElementVNode("span", {
            class: _normalizeClass(["badge text-uppercase", _ctx.getSettlementEntityStatus(item.status)])
          }, _toDisplayString(item.__show.status), 3)
        ]),
        "cell-created_at": _withCtx(({ row: item }) => [
          (item.created_at)
            ? (_openBlock(), _createElementBlock("div", _hoisted_13, _toDisplayString(item.created_at ? _ctx.formatDate(item.created_at) : "--"), 1))
            : (_openBlock(), _createElementBlock("div", _hoisted_14, "--"))
        ]),
        "cell-actions": _withCtx(({ row: item }) => [
          _createElementVNode("div", _hoisted_15, [
            _createVNode(_component_router_link, {
              to: 
                '/settlement-master-data/settlement-entity/' +
                item.id +
                '/overview'
              ,
              class: "btn btn-sm btn-icon btn-bg-light btn-active-color-primary",
              onClick: _cache[3] || (_cache[3] = _withModifiers(() => {}, ["stop"]))
            }, {
              default: _withCtx(() => [
                _createElementVNode("span", _hoisted_16, [
                  _createVNode(_component_inline_svg_icon, { src: "media/icons/duotune/general/gen004.svg" })
                ])
              ]),
              _: 2
            }, 1032, ["to"])
          ])
        ]),
        _: 1
      }, 8, ["loading", "table-data", "table-header", "current-page", "total", "rows-per-page", "onSelectChange", "onCurrentChange", "onItemsPerPageChange", "onSort"])
    ])
  ]))
}